import React from 'react'
import { ButtonStyle } from './style'

const BotaoTransparente = ({ onClick, children }) => {
  return (
    <ButtonStyle type="button" onClick={onClick}>
      {children}
    </ButtonStyle>
  );
};


export default BotaoTransparente