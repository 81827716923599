import React from 'react'
import { FooterStyle, Imagem, Container, FooterBottom, FooterContainer, Creditos, Label } from './style'
import Logo from '../../assets/img/LOGO_GOVERNO.png'
import { ACESSO_INTERNO, CREDITOS } from '../../utils/Constants';
import BotaoTransparente from '../botaoTransparente';
import Lock from '../../assets/icons/LockIcon';
import NavLinkNaoSublinhado from './style';

const FooterPrincipal = () => {

  return (
    <footer>

    <FooterContainer>
      <FooterStyle>
        <div>
          <Imagem src={Logo} alt='Logo Governo do Amazonas'/>
        </div>

        <Container>
          <NavLinkNaoSublinhado to="/AcessoInterno">
            <BotaoTransparente>
              <Lock />
              <Label>{ACESSO_INTERNO}</Label>
            </BotaoTransparente>
          </NavLinkNaoSublinhado>
        </Container>
      </FooterStyle>
      
      <FooterBottom>
        <Creditos>
          {CREDITOS}
        </Creditos>
      </FooterBottom>
    
    </FooterContainer>

    </footer>
  );
};


export default FooterPrincipal