import { BrowserRouter, Routes, Route } from "react-router-dom";
import BaseLayout from "./pages/BaseLayout";
import Home from "./pages/Home";
import Contato from "./pages/Contato"
import Duvidas from "./pages/Duvidas";
import Servicos from "./pages/Servicos";
import Theme from "./Theme";
import AcessoInterno from "./pages/AcessoInterno";


export default function App() {
  return (
    <>
      <Theme>
        <BrowserRouter>
          <BaseLayout>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/AcessoInterno" element={<AcessoInterno />} />
              <Route exact path="/contato" element={<Contato />} />
              <Route exact path="/duvidas" element={<Duvidas />} />
              <Route exact path="/servicos" element={<Servicos />} />
            </Routes>
          </BaseLayout>
        </BrowserRouter>
      </Theme>
    </>
  );
}
