import styled from 'styled-components'


export const CollapseContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  padding: 10px;
  border-radius:10px ;
  width:100%;
  background-color: rgb(255, 255, 255);
  display:flex;
  flex-direction: column;
  margin-bottom:20px;
  color: #000000;
  margin: 0 auto;
  margin-bottom: 20px;
`
export const CollapseHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #000000;

` 
export const TituloCollapse = styled.span`
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  line-height: 0.6;
`;

export const BodyCollapse = styled.div`
  width:100%;
  background-color: ${props => props.theme.colors.Branco};
  display:flex;
  flex-direction: column;
  margin-bottom:20px;
`;

export const ColorDescricao = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  line-height: 1.5;
  width: 100%;
  text-align: justify;
`;

export const BotaoMenu = styled.div`
  border: none;
  width:100%;
  background-color: rgb(255, 255, 255);
  display:flex;
  flex-direction: column;
  color: #000000;
  cursor: pointer;
  
`

export const IconCollapse = styled.div`
  font-size: 18px;
  justify-content: center;
  margin-right: 20px;
`