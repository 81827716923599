import React from "react";
import { SAIBA_SOBRE, SISTEMAS } from "../../../../utils/Constants";
import { TituloContainer, Titulo } from "../../style";
import { CardContainer } from "./style";
import CardSistemas from "../cardSistemas";
import { Slide, Slider } from "../../../../components/slider";

const SobreSistemas = ({ sistemas }) => {
  const sistemasFiltrados = [...sistemas].sort((a, b) =>
    a.nome > b.nome ? 1 : -1
  );
  const settings = {
    breakpoints: {
      1500: { slidesPerView: 4 },
      1200: { slidesPerView: 3 },
      820: { slidesPerView: 2 },
      600: { slidesPerView: 1 },
    },
    slidesPerView: 1.5,
    spaceBetween: 10,
    navigation: true,
  };

  return (
    <>
      <TituloContainer id="menuSistemas">
        <Titulo>{SAIBA_SOBRE}</Titulo>
        <Titulo negrito>{SISTEMAS}</Titulo>
      </TituloContainer>
      <CardContainer>
        <Slider settings={settings}>
          {sistemasFiltrados.map((item, index) => {
            return (
              <Slide key={index}>
                <CardSistemas sistema={item} />
              </Slide>
            );
          })}
        </Slider>
      </CardContainer>
    </>
  );
};

export default SobreSistemas;
