import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    box-shadow: 10px 10px 21px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    height: 297px;
    background: ${props => props.backgroundColor ? props.backgroundColor : 'none'};
    @media (max-width: 1280px) {
        height: 350px;
    }

`;

export const MenuServicos = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 24px;
`;

export const ItemServico = styled.div`
    font-style: normal;
    font-weight: ${props => props.active ? 500 : 300};
    font-size: 20px;
    line-height: 24px;
    padding: 8px 16px;
    align-items: center;

    color: ${props => props.theme.colors.Preto};

    background: ${props => props.active ? '#F6F9F6' : 'none'};
    box-shadow: ${props => props.active ? '14px 15px 21px rgba(0, 0, 0, 0.05)' : 'none'};
    border-radius: ${props => props.active ? '38px' : 'none'};

    &:hover {
        cursor: pointer;
    }
`;

export const Separador = styled.div`
    height: 80%;
    align-self: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
`;

export const ConteudoServico = styled.div`
    background-image: url(${props => props.imagem});
    background-repeat: no-repeat;
    background-position: right; 
    background-size: contain;
    width: 70%;
    display: flex;
    flex-direction: row;
`;

export const DescricaoServico = styled.div`
    padding:  24px;
    width: 70%;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    color: ${props => props.theme.colors.Preto};
    text-align: justify;
    @media (max-width: 1280px) {
        font-size: ${({ id }) => (id === 5 ? "18px" : "20px")};
        line-height: ${({ id }) => (id === 5 ? "27.5px" : "32px")};
    }
`;

export const AcoesServicos = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-self: end;
    gap: 16px;
    
`;

export const BotaoServico = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 8px 16px;
    width: 260px;
    height: 40px;
    background: ${props => props.botaoAcesso ? props.theme.colors.Verde : props.theme.colors.Branco};
    box-shadow: 14px 15px 21px rgba(0, 0, 0, 0.05);
    border-radius: 230px;

    &:hover {
        cursor: pointer;
    }
`;

export const TextoBotao = styled.span`
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    /* Texto/Primária */

    color: ${props => props.botaoAcesso ? props.theme.colors.Branco : props.theme.colors.Preto};
`;
