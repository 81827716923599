import React from 'react'
import Modal from 'react-modal';
import { AbasContainer, BotaoAba, BotaoCard, Card, CardBody, CardHeader, LogoCard, Separador, TextoBody, TextoBotao, 
    TextoContainer, TituloCard, TituloContainer, ModalContainer, ModalBody, LinkManual, StyledModal, TituloModal, SubtituloModal, 
    ConteudoModal, ModalHeader, CloseModal } from './style'
import { useState } from 'react'
import LogoSISLAM from "../../../../assets/img/LogoSISLAM.png";
import LogoEntradaUnica from "../../../../assets/img/LogoEntradaUnica.png";
import LogoCarteiraDePesca from "../../../../assets/img/LogoCarteiraDePesca.png";
import LogoPainelDeInformacoes from "../../../../assets/img/LogoPainelDeInformacoes.png";
import { MANUAL, ORIENTACOES_GERAIS, PRIMEIRO_ACESSO } from '../../../../utils/Constants';
import CloseModalIcon from '../../../../assets/icons/CloseModalIcon';


const CardSistemas = ({sistema}) => {
    const [abaSistema] = useState(1);
    
    const [modalAberta, setModalAberta] = useState(false);
    const abrirModal = () => {
        setModalAberta(true);
      };

    return (
        <>
            <Card>
                <CardHeader>
                    <LogoCard src={sistema.nome === 'SISLAM - Sistema de Licenciamento Ambiental - IPAAM' ? LogoSISLAM
                        : sistema.nome === 'Sistema Entrada Única' ? LogoEntradaUnica
                        : sistema.nome === 'Sistema Carteira de Pesca' ? LogoCarteiraDePesca 
                        : LogoPainelDeInformacoes} alt='Logo'/>
                    <TituloContainer>
                        <TituloCard>{sistema.nome}</TituloCard>
                    </TituloContainer>
                </CardHeader>
                <Separador/>
                <CardBody>
                    <AbasContainer>
                        <BotaoAba active={abaSistema === 1}>Sobre o sistema</BotaoAba>
                        {(sistema.orientacoes || sistema.primeiroAcesso || sistema.manual ) && (
                            <BotaoAba onClick={() => abrirModal()}>Orientações</BotaoAba>
                        )}
                    </AbasContainer>
                        <TextoContainer>
                            <TextoBody>
                            {sistema.descricao}
                            </TextoBody>
                        </TextoContainer>
                    <BotaoCard onClick={() => window.open(sistema.link, '_blank', 'noreferrer')}>
                        <TextoBotao>Acessar</TextoBotao>
                    </BotaoCard>
                </CardBody>
            </Card>
            {(sistema.orientacoes || sistema.primeiroAcesso || sistema.manual ) && (
                <Modal 
                    isOpen={modalAberta}
                    style={StyledModal}
                >
                    <ModalContainer>
                        <ModalHeader>
                            <TituloModal>{sistema.nome}</TituloModal>
                            <CloseModal onClick={() => setModalAberta(false)}>
                                <CloseModalIcon/>
                            </CloseModal>
                        </ModalHeader>
                        <ModalBody>
                            <SubtituloModal>{ORIENTACOES_GERAIS}</SubtituloModal>
                            <ConteudoModal>
                                {sistema.orientacoes}
                            </ConteudoModal>

                            <SubtituloModal>{PRIMEIRO_ACESSO}</SubtituloModal>
                            <ConteudoModal dangerouslySetInnerHTML={{ __html: sistema.primeiroAcesso }}/>

                            <SubtituloModal>{MANUAL}</SubtituloModal>
                            <ConteudoModal onClick={() => window.open(sistema.manual, '_blank', 'noreferrer')}>
                                <LinkManual>{sistema.nome} (Baixar Manual)</LinkManual>
                            </ConteudoModal>
                        </ModalBody>
                    </ModalContainer>
                </Modal>
            )}
        </>
    )
}

export default CardSistemas