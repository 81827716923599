import styled from 'styled-components'

export const ButtonStyle = styled.button`
  background: transparent;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.Azul400};
  color: ${props => props.theme.colors.Branco};
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 12px 16px;
  gap: 10px;
  cursor: pointer;
  
  &:hover,
  &:focus {
    border: 2px solid ${props => props.theme.colors.Azul400};
  }
`