import styled from "styled-components";

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 16px;
    width: 315px;
    height: 540px;
    background: ${props => props.theme.colors.Branco};
    box-shadow: 10px 10px 21px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin: 20px;
`;

export const CardHeader = styled.div`
    display: flex;
    margin: 0% auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 48px 24px 48px;
`;

export const LogoCard = styled.img`
    width: 175px;
    height: 55px;
`;

export const TituloCard = styled.span`
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    position: absolute;
    align-items: center;
    transform: translate(-50%, 0%);
    margin: 10px 0;
    color: ${props => props.theme.colors.Preto};
    `;

export const TituloContainer = styled.div`
    text-align: center;
    padding-top: 1rem;
    height: 1.5rem;
`;

export const Separador = styled.div`
    width: 100%;
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.15);
`;

export const AbasContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
`;

export const BotaoAba = styled.div`
    font-weight: ${props => props.active ? 600 : 400};
    font-size: 16px;
    line-height: 19px;
    margin-right: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    border-bottom: ${props => props.active ? '2px solid #00C254' : 'none'};

    &:hover {
        cursor: pointer;
    }
`;

export const TextoContainer = styled.div`
    width: 270px;
    height: 176px;
    margin-bottom: 16px;
    position: relative;
`;

export const TextoBody = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: auto;
    color: ${props => props.theme.colors.Preto};

`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 24px;
    text-align: justify;
`;

export const BotaoCard = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 8px 16px;
    width: 15.5rem;
    bottom: 0%;
    height: 2rem;
    background: ${props => props.theme.colors.Verde};
    box-shadow: 14px 15px 21px rgba(0, 0, 0, 0.05);
    border-radius: 230px;
    margin-bottom: 15px;
    &:hover {
        cursor: pointer;
    }
`;

export const TextoBotao = styled.span`
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;

    /* Texto/Primária */

    color: ${props => props.theme.colors.Branco};
`;

export const ModalContainer = styled.div`
    align-items: center;
    flex-direction: column;
`;

export const CloseModal = styled.span`
    display: flex;
    justify-content: end;
    font-size: 25px;
    &:hover {
        cursor: pointer;
        background-color: rgb(255,0,0, 0.3 );
    }
`;

export const ModalHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const TituloModal = styled.span`
    font-family: 'SF Pro Display';
    font-weight: ${props => (props.negrito ? 'bold' : 600)};
    font-size: 24px;
    display: flex;
    color: ${props => props.theme.colors.secundaria};
`;

export const SubtituloModal = styled.span`
    font-family: 'SF Pro Display';
    font-weight: ${props => (props.negrito ? 'bold' : 300)};
    font-size: 24px;
    line-height: 29px;
    padding: 32px 5px 16px 0px;
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.secundaria};
`;

export const ModalBody = styled.div`
    position: relative;
    margin: auto;
`;

export const ConteudoModal = styled.span`
    font-family: 'SF Pro Display';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: auto;
    color: ${props => props.theme.colors.secundaria};
    position: relative;
    padding-bottom: 25px;
`;

export const LinkManual = styled.span`
    margin-bottom: 20px;
    position: relative;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const StyledModal = {
    overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    },

    content: {
    boxShadow: '20px 20px 60px #bebebe, -20px -20px 60px #ffffff',
    display: 'flex',
    position: 'relative',
    inset: 'auto',
    border: 'none',
    background: '#fff',
    width: '700px',
    height: 'auto',
    maxHeight: '500px',
    marginTop:'20px',
    marginBottom: '20px',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '20px',
    outline: 'none',
    padding: '40px',
    zIndex: '1'
    },

}