import React, { useEffect, useState } from "react";
import { getDuvidas } from "../../services/duvidas";
import Collapse from "./components/collapse";
import {
  HeaderDuvidas,
  HeaderArrow,
  ButtonVoltar,
  TitlePerguntas,
} from "./components/style";
import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon";

const Duvidas = () => {
  const navigate = useNavigate();
  const [duvidas, setDuvidas] = useState([]);

  const setDadosDuvidas = async () => {
    const data = await getDuvidas();
    setDuvidas(data);
  };

  useEffect(() => {
    setDadosDuvidas();
  }, []);

  return (
    <div>
      <HeaderDuvidas>
        <ButtonVoltar onClick={() => navigate("/")}>
          <HeaderArrow>
            <span>
              <ArrowLeftIcon />
            </span>
          </HeaderArrow>
          <p>Voltar </p>
        </ButtonVoltar>
        <p> | Dúvidas</p>
      </HeaderDuvidas>
      <TitlePerguntas>
        <p>Perguntas frequentes</p>
      </TitlePerguntas>
      {duvidas.map((duvida, index) => {
        return <Collapse key={index} item={duvida} />;
      })}
    </div>
  );
};
export default Duvidas;
