import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import Arrow from "../../../../assets/icons/ArrowIcon";
import VideoBackground from "../../../../assets/video/LandingPageIPAAM.mp4";
import {
  BotaoMenu,
  BotoesContainer,
  DropDownContent,
  DropDownLi,
  DropdownItem,
  ItensContainer,
  Lista,
  MenuContainer,
  PainelContainer,
  Separador,
  TextoDropdown,
  TextoItem,
  TextoMenu,
  Titulo,
  VideoContainer,
  VideoPlayer,
} from "./style";

const PainelServicos = ({ setActiveServico }) => {
  const navigate = useNavigate();
  const itens = [
    { nome: "Dúvidas", click: () => navigate("/duvidas") },
    { nome: "Contato", click: () => navigate("/contato") },
    {
      nome: "Página Inicial IPAAM",
      click: () => window.open("http://www.ipaam.am.gov.br/"),
      title: "www.ipaam.am.gov.br",
    },
  ];

  const itensDropdown = {
    servicos: [
      {
        nome: "Licenciamento de Fauna / Pesca",
        click: () => {
          const accordionName = "FAUNA_PESCA";
          navigate(`/servicos?accordionName=${accordionName}`);
        },
      },
      {
        nome: "Licenciamento de Rec. Minerais / Hídricos",
        click: () => {
          const accordionName = "RECURSOS_MINERAIS_HIDRICOS";
          navigate(`/servicos?accordionName=${accordionName}`);
        },
      },
      {
        nome: "Licenciamento Florestal / Agropecuário",
        click: () => {
          const accordionName = "FLORESTAL_AGROPECUARIO";
          navigate(`/servicos?accordionName=${accordionName}`);
        },
      },
      {
        nome: "Licenciamento Industrial",
        click: () => {
          const accordionName = "INDUSTRIAL";
          navigate(`/servicos?accordionName=${accordionName}`);
        },
      },
      {
        nome: "Dispensa de Licenciamento",
        click: () => {
          const accordionName = "DLI";
          navigate(`/servicos?accordionName=${accordionName}`);
        },
      },
      {
        nome: "Demais Atividades e Serviços",
        click: () => {
          const accordionName = "OUTROS";
          navigate(`/servicos?accordionName=${accordionName}`);
        },
      },
    ],
    sistemas: [
      {
        nome: "SISLAM - Sistema de Licenciamento Ambiental - IPAAM",
        click: () =>
          window.open("https://sistemas.ipaam.am.gov.br/licenciamento/#/login"),
      },
      {
        nome: "Sistema de Entrada Única",
        click: () =>
          window.open("http://sistemas.ipaam.am.gov.br/portal-seguranca/#/"),
      },
      {
        nome: "Sistema Carteira de Pesca",
        click: () =>
          window.open("http://sistemas.ipaam.am.gov.br/carteira-pesca/"),
      },
      {
        nome: "Dashboard - Painel de Informações",
        click: () =>
          window.open("http://sistemas.ipaam.am.gov.br/dashboard/#/"),
      },
    ],
  };

  return (
    <PainelContainer>
      <MenuContainer>
        <Titulo>{"Portal de Serviços"}</Titulo>
        <ItensContainer>
          <BotoesContainer>
            <DropDownLi>
              <Link
                to="menuServicos"
                smooth={true}
                offset={-100}
                duration={500}
              >
                <BotaoMenu>
                  <TextoItem>Serviços</TextoItem>
                  <Arrow />
                </BotaoMenu>
              </Link>
              <DropDownContent className="drop-container">
                {itensDropdown.servicos.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <DropdownItem key={index}>
                        <TextoMenu onClick={() => item.click()}>
                          {item.nome}
                        </TextoMenu>
                      </DropdownItem>
                      {index < itensDropdown.servicos.length - 1 && (
                        <Separador />
                      )}
                    </Fragment>
                  );
                })}
              </DropDownContent>
            </DropDownLi>
            <DropDownLi>
              <Link
                to="menuSistemas"
                smooth={true}
                offset={-100}
                duration={500}
              >
                <BotaoMenu>
                  <TextoItem>Sistemas</TextoItem>
                  <Arrow />
                </BotaoMenu>
              </Link>
              <DropDownContent className="drop-container">
                {itensDropdown.sistemas.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <DropdownItem key={index}>
                        <TextoMenu onClick={() => item.click()}>
                          {item.nome}
                        </TextoMenu>
                      </DropdownItem>
                      {index < itensDropdown.servicos.length - 1 && (
                        <Separador />
                      )}
                    </Fragment>
                  );
                })}
                <DropdownItem>
                  <TextoDropdown onClick={() => navigate("/AcessoInterno")}>
                    Acesso Interno
                  </TextoDropdown>
                </DropdownItem>
              </DropDownContent>
            </DropDownLi>
          </BotoesContainer>
          <Lista>
            {itens.map((item, index) => {
              return (
                <li key={index}>
                  <BotaoMenu onClick={() => item.click()} title={item.title}>
                    <TextoItem>{item.nome}</TextoItem>
                  </BotaoMenu>
                </li>
              );
            })}
          </Lista>
        </ItensContainer>
      </MenuContainer>
      <VideoContainer>
        <VideoPlayer src={VideoBackground} autoPlay loop muted />
      </VideoContainer>
    </PainelContainer>
  );
};

export default PainelServicos;
