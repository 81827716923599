import React from 'react';

const Whatsapp = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M27.5669 8.44189C25.0269 5.90186 21.6519 4.5 18.0571 4.5C10.6479 4.5 4.61426 10.5293 4.61426 17.9385C4.60986 20.3071 5.22949 22.6187 6.40723 24.6577L4.5 31.623L11.6279 29.751C13.5879 30.8232 15.8027 31.3857 18.0527 31.3901H18.0571C25.4663 31.3901 31.4956 25.3608 31.5 17.9473C31.5 14.3569 30.1025 10.9819 27.5669 8.44189ZM18.0571 29.1182H18.0527C16.0488 29.1182 14.0801 28.5776 12.3662 27.5625L11.9575 27.3208L7.72559 28.4282L8.85498 24.3062L8.59131 23.8843C7.4707 22.1045 6.88184 20.0479 6.88184 17.9385C6.88184 11.7817 11.896 6.77197 18.0615 6.77197C21.0454 6.77197 23.8491 7.93652 25.9585 10.0459C28.0679 12.1597 29.228 14.9634 29.228 17.9473C29.228 24.1084 24.2139 29.1182 18.0571 29.1182ZM24.1831 20.751C23.8491 20.584 22.1968 19.771 21.8892 19.6611C21.5815 19.5469 21.3574 19.4941 21.1333 19.8281C20.9092 20.1665 20.2676 20.9224 20.0698 21.1465C19.8765 21.3662 19.6787 21.397 19.3447 21.23C19.0063 21.063 17.9253 20.707 16.6421 19.5601C15.6445 18.6724 14.9678 17.5693 14.7744 17.2354C14.5767 16.897 14.7524 16.7168 14.9194 16.5498C15.0732 16.4004 15.2578 16.1587 15.4248 15.9609C15.5918 15.7676 15.6489 15.627 15.7632 15.4028C15.873 15.1787 15.8159 14.981 15.7324 14.814C15.6489 14.647 14.9766 12.9902 14.6953 12.3179C14.4229 11.6631 14.146 11.7554 13.9395 11.7422C13.7461 11.7334 13.522 11.7334 13.2979 11.7334C13.0737 11.7334 12.709 11.8169 12.4014 12.1553C12.0938 12.4893 11.228 13.3022 11.228 14.9546C11.228 16.6069 12.4321 18.2065 12.5991 18.4307C12.7661 18.6504 14.9678 22.043 18.3384 23.4976C19.1382 23.8447 19.7622 24.0513 20.25 24.2051C21.0542 24.46 21.7881 24.4248 22.3682 24.3413C23.0142 24.2446 24.3545 23.5283 24.6357 22.7417C24.9126 21.9595 24.9126 21.2871 24.8291 21.1465C24.7456 21.0059 24.5215 20.9224 24.1831 20.751Z" fill="white"/>
    </svg>
  )
}

export default Whatsapp;