import styled from 'styled-components'

export const DivPrincipal = styled.div`
    padding: 32px;
    height: 100%;
    position: relative;

    @media screen and (max-height: 720px) and (min-height: 0px){
    min-height: 62.8vh;
    }

    @media screen and (max-height: 900px) and (min-height: 721px){
    min-height: 70.2vh;
    }

    @media screen and (max-height: 1080px) and (min-height: 901px){
    min-height: 75.2vh;
    }

    @media screen and (max-height: 1440px) and (min-height: 1081px){
    min-height: 81.4vh;
    }

    @media screen and (max-height: 2160px) and (min-height: 1441px){
    min-height: 87.6vh;
    }

    @media screen and (max-height: 4320px) and (min-height: 2161px){
    min-height: 93.8vh;
    }

    @media screen and (max-height: 99999px) and (min-height: 4321px){
    min-height: 100vh;
    }
`