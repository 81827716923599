import styled from 'styled-components'


export const HeaderServicos = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  height: 24px;
  color: #295661;
  margin-bottom: 20px;
`

export const HeaderArrow = styled.span`
  display: flex;
  align-items: center;
  /* font-size: 28px; */
  font-weight: bold;
  align-items: center;
  margin-top: 5px;
  color: #295661;
  margin-right: 5px;
`

export const ButtonVoltar = styled.div`
  border: none;
  display:flex;
  color: #295661;
  cursor: pointer;
  margin-right: 5px;
  `

export const Pesquisar = styled.div`
  border: none;
  display:flex;
  color: #295661;
  margin-left: 10px;
  font-weight:bolder;
  font-size: 30px;
`

export const Breadcrumb = styled.div`
  font-size: 16px;
  text-decoration: none;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
`
export const AgendaTextContainer = styled.div`
  margin-bottom: 20px;
`

export const AgendaTextTitle = styled.span`
  font-size: 17px;
  font-weight: bold;
  color: #295661;
`
export const AgendaTextBody = styled.p`
  text-align: justify;
  font-size: 14px;
  color: #295661;
`

export const Content = styled.div `
  padding-left: 30px;
  /* position: fixed;
  width: 47%;
  flex-direction: column;
  display: flex;
  bottom: 50%;
  right: 15px;
  padding: 8% 0%;
  height: 10%; comentado para no futuro quando menu direito for flutuante, já facilitar*/
`