import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    Branco: "#F6F9F6",
    BrancoSecundario: "#FFFFFF",
    secundaria: "#295661",
    Azul600: "#1F4751",
    Azul400: "#14A2B8",
    Verde: "#00C254",
    Preto: "#414141",
    AmareloClaro: "#F2EEE3",

  },
  fonts: ["sans-serif", "SF Pro Display"],
  fontSizes: {
    small: "1rem",
    medium: "2rem",
    large: "36px",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
