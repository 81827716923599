export const TEXTOS_SERVICO = [
    {
        titulo: 'LICENCIAMENTO DE FAUNA/PESCA',
        texto: 'texto paras testar estrutura' 
    },
]

export const URL_IPAAM = 'http://www.ipaam.am.gov.br/';
export const URL_PORTAL_SEGURANCA = 'http://sistemas.ipaam.am.gov.br/portal-seguranca/';
export const URL_MAPA_SITE = `${URL_IPAAM}mapa-do-site/`;
export const MAPA_SITE = 'MAPA DO SITE';
export const ACESSIBILIDADE = 'ACESSIBILIDADE';
export const CONTRASTE = 'CONTRASTE';
export const CREDITOS = 'powered by GT4W';
export const ACESSO_INTERNO = 'Acesso Interno';
export const NUMERO = 'Números do ';
export const SISLAM = 'SISLAM';
export const SAIBA_SOBRE = 'Saiba mais sobre';
export const INICIO = 'Início'
export const SERVICOS = 'Serviços';
export const SISTEMAS = 'Sistemas';
export const LICENCIAMENTO_FLORESTAL = 'Licenciamento Florestal / Agropecuário';
export const LICENCIAMENTO_INDUSTRIAL = 'Licenciamento Industrial';
export const LICENCIAMENTO_FAUNA = 'Licenciamento de Fauna / Pesca';
export const LICENCIAMENTO_HIDRICOS = 'Licenciamento de Rec. Minerais / Hídricos';
export const DISPENSA_LICENCIAMENTO = 'Dispensa de Licenciamento';
export const DEMAIS_ATIVIDADES_SERVICOS = 'Demais Atividades e Serviços';
export const LICENCIAMENTO_FLORESTAL_DESCRICAO = 'Compreende os serviços prestados pelo IPAAM, visando regularidade e fiscalização dos diferentes tipos de manejos de espécies florestais nativas e suas devidas compensações florestais, bem como a conformidade no atendimento as exigências normativas da produção de culturas e seus processamentos agropecuários.';
export const LICENCIAMENTO_INDUSTRIAL_DESCRICAO = 'Processo para a concessão de autorização para a instalação, ampliação e a operação de empreendimentos e indústrias que utilizam de recursos ambientais nos processos produtivos, considerando os potenciais riscos de poluição, ou de degradação ambiental.';
export const LICENCIAMENTO_FAUNA_DESCRICAO = 'Regularização ambiental que trata da captura, coleta e transporte, criação ou manejo de fauna silvestre terrestre ou aquática; e solicitação de autorização para prática de pesca esportiva ou recreativa, executada de forma legal segundo requisitos estabelecidos.';
export const LICENCIAMENTO_HIDRICOS_DESCRICAO = 'Procedimento o qual irá conceber a autorização de execução de processos de extração mineral e utilização de recurso hídrico ou qualquer intervenção que venha a alterar o regime, a quantidade ou a qualidade do corpo de água.';
export const DISPENSA_LICENCIAMENTO_DESCRICAO = 'A DECLARAÇÃO DE INEXIGIBILIDADE - DI, ato administrativo que declara que uma atividade é dispensada de Licenciamento Ambiental, deve ser solicitada para as atividades consideradas com potencial poluidor/degradador reduzido, conf. Art. 6º e comparativo ao disposto do Anexo da Lei Estadual 3.785, DE 24 DE JULHO DE 2012, não eximindo o empreendedor de estar regularizado para as demais atividades que necessitem de autorização de supressão vegetal e/ou licenciamento no mesmo local.';
export const DEMAIS_ATIVIDADES_SERVICOS_DESCRICAO = 'A Gerencia de Gestão de Documentos e Protocolo – GEPR realiza o atendimento, cadastramento e orientações ao público em geral, recebendo, monitorando e controlando documentos diversos, dados cadastrais e demais serviços administrativos requeridos junto a este setor.';
export const ACESSO = 'Acesso';
export const MODULOS_DE_USO = 'Módulos de uso';
export const INTERNO = 'Interno';
export const DESCRICAO_MODULOS_INTERNO = 'Sistemas com a finalidade de análise e controle das solicitações geradas por Pessoas Físicas ou Jurídicas junto aos sistemas do IPAAM, bem com Controle e Fiscalização.';
export const VOLTAR = 'Voltar';
export const ORIENTACOES_GERAIS = 'Orientações Gerais';
export const PRIMEIRO_ACESSO = 'Primeiro Acesso';
export const MANUAL = 'Manual'