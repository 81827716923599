import styled from "styled-components";

export const PainelContainer = styled.div`
  width: 100%;
  height: 600px;
  border-radius: 20px;
  background-color: ${props => props.theme.colors.secundaria};
  box-shadow: 10px 10px 21px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
`;

export const MenuContainer = styled.div`
  width: 20%;
  padding: 24px 30px 24px 24px;
`;

export const VideoContainer = styled.div`
  width: 100%;
  background-size: 100%;
`;

export const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px
`;

export const ItensContainer = styled.div`
  height: 90%;
  margin-top: 16px;
  display: flex;
  border-left: 1px solid ${props => props.theme.colors.BrancoSecundario};
  justify-content: space-between;
  flex-direction: column;
`;

export const TextoItem = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.Branco};
`;

export const BotaoMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 5px;

  width: 291px;
  height: 38px;
  background: transparent;

  @media (max-width: 1280px) {
    width: 211px;
  }
  &:hover {
    cursor: pointer;
    background: ${props => props.theme.colors.Verde};
    transition-duration: 0.4s;

    ${TextoItem} {
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
    }
  }

`

export const Titulo = styled.span`
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.BrancoSecundario};
  text-shadow: 6.72941px 6.72941px 7.57059px rgba(0, 0, 0, 0.25);

  @media (max-width: 1520px) {
    font-size: 30px;
  }

  @media (max-width: 1280px) {
    font-size: 25px;
  }

  @media (max-width: 1080px) {
    font-size: 18px;
  }

  @media (max-width: 800px) {
    font-size: 16px;
  }  
`;

export const Lista = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const BotoesContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;


export const DropDownContent = styled.div`
  display: none;
  position: absolute;
  left: 23.8rem;
  background-color: ${props => props.theme.colors.Verde};
  border-radius: 5px;
  padding: 6px 16px;
  z-index: 1;
  @media (max-width: 1280px) {
    left: 19rem;
  }
`;

export const StyledLi = styled.li`
    float: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-radius: 5px;
`;

export const DropDownLi = styled(StyledLi)`
  &:hover {
    display: flex;
    flex-direction: row;
    border-radius: 5px;

    width: 320px;
    height: 54px;
    background: ${props => props.theme.colors.Verde};
    @media (max-width: 1280px) {
      width: 244px;
      height: 54px;
    }
  }
  &:hover ${DropDownContent} {
    display: block;
    transition-duration: 0.5s;
  }
`;

export const DropdownItem = styled.span`
  color: black;
  text-decoration: none;
  display: block;
  cursor: pointer;
`;

export const TextoDropdown = styled.span`
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  padding: 6px 0px 6px 0px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.Branco};
`;

export const TextoMenu = styled.span`
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  padding: 6px 0px 6px 0px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.Branco};
  text-decoration: none;
  scroll-behavior: smooth !important;
`;

export const Separador = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.3);
`;
