import api from "./api"

export const downloadArquivo = async (nomeArquivo) => {
  try {
    const response = await api.get(`/api/arquivo/downloadFile?fileName=${nomeArquivo}`, {
      responseType: 'blob'
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export const getAgendas = async () => {
  try {
    const fetchData = await api.get('/api/servico/agendas')
    return fetchData.data;
  } catch (error) {
    console.log(error)
  }
}

export const getServicosByCodAmbiental = async (codAmbiental) => {
  try {
    const fetchData = await api.get('/api/servico/requisitos/atividade/' + codAmbiental)
    return fetchData.data;
  } catch (error) {
    console.log(error)
  }
}

