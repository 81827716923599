import React from 'react'
import { InputStyle } from './style'

const Input = ({ handleChange, value, handleKeyPress }) => {
  return (
    <>
      <InputStyle 
        placeholder='Pesquisar...' 
        onChange={handleChange} 
        value={value}
        onKeyPress={event => {
          if (event.key === 'Enter' && value.length > 0) {
            handleKeyPress()
          }
        }}
      />
    </>
  );
};


export default Input