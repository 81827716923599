import api from "./api";


const cadastrar = async (value) => {
  const validateEmail = (value) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(value);
  }
  
  const isValidEmail = validateEmail(value);
  if (isValidEmail && isValidEmail.length !== 0) {
    alert('E-mail cadastrado com sucesso!');
    const fetchData = await api.post('/api/contato', 
    {
      email: value
    })
    return fetchData.data
  } else {
      alert('Formato de e-mail inválido');
    }
  }
  export default cadastrar;
