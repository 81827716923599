import React, { useState } from 'react'
import {CollapseContainer ,CollapseHeader,TituloCollapse,ColorDescricao,BotaoMenu,IconCollapse } from '../collapse/style'
import Grow from '@material-ui/core/Grow';
import Collaps from '@material-ui/core/Collapse';

const Collapse = ({item}) => {
  const [collapseAberto, setCollapseAberto] = useState(false);

  return (
    <div>
    <CollapseContainer>
    <Collaps in={collapseAberto} collapsedSize={30}>

      <BotaoMenu onClick={() => setCollapseAberto(!collapseAberto)}>
        <CollapseHeader>
          <TituloCollapse>{item.titulo}</TituloCollapse>
          {
          collapseAberto ?  <IconCollapse>↴</IconCollapse> : <IconCollapse>↰</IconCollapse>
          }
        </CollapseHeader>
      </BotaoMenu>
      {
        collapseAberto && (
          <Grow in={collapseAberto}>
          <ColorDescricao>
            <div dangerouslySetInnerHTML={{ __html: item.descricao }} />
          </ColorDescricao>
          </Grow>
        )
      }

    </Collaps>
    </CollapseContainer>
    </div>
  );
}

export default Collapse