import styled from 'styled-components'

export const BotoesContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;


export const DropDown = styled.div`

  position: absolute;
  top: 4.6rem;
  right: 2.5rem;
  background-color: ${props => props.theme.colors.Verde};
  border-radius: 5px;
  padding: 6px 16px;
  z-index: 1;
`;

export const DropDownAcessoInterno = styled.div`
  position: absolute;
  top: 4.6rem;
  right: 16.6rem;
  background-color: ${props => props.theme.colors.Verde};
  border-radius: 5px;
  padding: 6px 16px;
  z-index: 1;
`;

export const TextoItem = styled.div`
  font-weight: 400;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap: 10px; */
  color: ${props => props.theme.colors.Branco};
`;

export const BotaoMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 5px;

  height: 38px;
  background: transparent;

  &:hover {
    cursor: pointer;
    background: ${props => props.theme.colors.Verde};
    transition-duration: 0.4s;
  }

`

export const ButtonStyle = styled.button`
  background: transparent;
  border-radius: 3px;
  border: 2px solid ${props => props.theme.colors.gray};
  color: ${props => props.theme.colors.gray};
  margin: 0 1em;
  padding: 0.25em 1em;
  cursor: pointer;
  
  &:hover,
  &:focus {
    color: ${props => props.theme.colors.green};
    border: 2px solid ${props => props.theme.colors.green};
  }
`
export const PesquisaContainer = styled.div`
  position:relative;
  display: flex;
  justify-content: end;
  align-items: center;
`

export const BotaoFechar = styled.div`
  position: absolute;
  box-sizing:border-box;
  margin-right: 1em;

  &:hover{
    cursor: pointer;
  };
`

export const HeaderStyle = styled.div`
  width: 100%;
  height: 98px;
  background-color: ${props => props.theme.colors.secundaria};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Imagem = styled.img`
  width: 112px;
  height: 40px;
  padding-left: 32px;
`

export const Container = styled.div`
  padding-right: 33px;
`

export const MenuContainer = styled.ul`
  list-style-type: none;
  overflow: hidden;
  margin: 0px 0px 16px 0px;
  padding: 0px;
`

export const MenuItem = styled.li`
  float: left;
  border-right: 1px solid white;
  padding: 0 0.5em;

  :last-child {
    border-right: none;
  }
`

export const Link = styled.a `
  display: block;
  color: ${props => props.theme.colors.Branco};
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  text-align: center;
  text-decoration: none;

  hover {
    cursor: pointer;
  }
`