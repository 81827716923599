import React, { useState } from "react";
import {
  DropDownAcessoInterno,
  DropDown,
  TextoItem,
  BotaoMenu,
  HeaderStyle,
  Imagem,
  Container,
  MenuContainer,
  MenuItem,
  Link,
  BotaoFechar,
  PesquisaContainer,
} from "./style";
import Logo from "../../assets/img/LOGO_GOVERNO.png";
import { useNavigate } from "react-router-dom";
import Input from "../input";
import CloseIcon from "../../assets/icons/CloseIcon";
import {
  ACESSIBILIDADE,
  CONTRASTE,
  MAPA_SITE,
  URL_IPAAM,
  URL_MAPA_SITE,
} from "../../utils/Constants";
import { useLocation } from "react-router-dom";
import ArrowDownIcon from "../../assets/icons/ArrowDownIcon";

const HeaderPrincipal = () => {
  const navigate = useNavigate();
  const [dropdownAtivo, setDropdownAtivo] = useState(false);
  const [acessoInterno, subAcessoInterno] = useState(false);
  const itens = [
    { nome: "Página inicial", click: () => navigate("/") },
    {
      nome: "Acesso Interno",
      click: () => subAcessoInterno(!acessoInterno),
      subAcessoInterno: acessoInterno,
      subAcesso: [
        {
          nome: "Análise - SISLAM",
          click: () =>
            window.open("http://sistemas.ipaam.am.gov.br/portal-seguranca/#/"),
        },
        { nome: "Fiscalização Offline" },
        {
          nome: "Fiscalização Online",
          click: () =>
            window.open("http://sistemas.ipaam.am.gov.br/fiscalizacao/login"),
        },
        {
          nome: "Gestão de Demandas",
          click: () =>
            window.open(
              "http://sistemas.ipaam.am.gov.br/gestao-demandas/#/login"
            ),
        },
        {
          nome: "Sistema de Informações Geoespaciais",
          click: () =>
            window.open("http://sistemas.ipaam.am.gov.br/sig/#/login"),
        },
      ],
    },
    {
      nome: "Sistemas",
      click: () => setDropdownAtivo(!dropdownAtivo),
      dropdown: dropdownAtivo,
      subItens: [
        {
          nome: "SISLAM - Sistema de Licenciamento Ambiental - IPAAM",
          click: () =>
            window.open(
              "http://sistemas.ipaam.am.gov.br/licenciamento/#/login"
            ),
        },
        {
          nome: "Sistema de Entrada Única",
          click: () =>
            window.open("http://sistemas.ipaam.am.gov.br/portal-seguranca/#/"),
        },
        {
          nome: "Sistema Carteira de Pesca",
          click: () =>
            window.open("http://sistemas.ipaam.am.gov.br/carteira-pesca/"),
        },
        {
          nome: "Dashboard - Painel de Informações",
          click: () =>
            window.open("http://sistemas.ipaam.am.gov.br/dashboard/"),
        },
        { nome: "Acesso Interno", click: () => navigate("/AcessoInterno") },
      ],
    },
    { nome: "Dúvidas", click: () => navigate("/duvidas") },
    { nome: "Contato", click: () => navigate("/contato") },
  ];

  const [pesquisa, setPesquisa] = useState("");
  const location = useLocation();

  const AlterarPesquisa = (event) => {
    setPesquisa(event.target.value);
  };

  const LimparPesquisa = () => {
    setPesquisa("");
  };

  const Pesquisar = () => {
    const url = `${URL_IPAAM}?s=${pesquisa}`;
    window.open(url, "_blank", "noreferrer");
  };
  const router = [{ nome: "/" }];

  const isHomePage = router.find((pagina) => pagina.nome === location.pathname);
  return (
    <HeaderStyle>
      <div>
        <a href="/">
          <Imagem src={Logo} alt="Logo Governo do Amazonas" />
        </a>
      </div>
      <Container>
        <section>
          {isHomePage ? (
            <>
              <MenuContainer>
                <MenuItem>
                  <Link href="#">{ACESSIBILIDADE}</Link>
                </MenuItem>
                <MenuItem>
                  <Link href="#">{CONTRASTE}</Link>
                </MenuItem>
                <MenuItem>
                  <Link href={URL_MAPA_SITE} target="_blank">
                    {MAPA_SITE}
                  </Link>
                </MenuItem>
              </MenuContainer>
              <PesquisaContainer>
                <Input
                  handleChange={AlterarPesquisa}
                  value={pesquisa}
                  handleKeyPress={Pesquisar}
                ></Input>
                <BotaoFechar onClick={LimparPesquisa}>
                  <CloseIcon />
                </BotaoFechar>
              </PesquisaContainer>
            </>
          ) : (
            <>
              <div style={{ display: "flex" }}>
                {itens.map((item, index) => (
                  <BotaoMenu
                    key={index}
                    onClick={item.click}
                    onMouseLeave={() =>
                      setDropdownAtivo(false) || subAcessoInterno(false)
                    }
                  >
                    <TextoItem>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {item.nome}
                        {item.nome === "Sistemas" ||
                        item.nome === "Acesso Interno" ? (
                          <ArrowDownIcon />
                        ) : null}
                      </div>
                    </TextoItem>
                    {item.dropdown && (
                      <DropDown
                        ativo={dropdownAtivo}
                        onMouseLeave={() => setDropdownAtivo(false)}
                      >
                        {item.subItens.map((subItem, index) => (
                          <BotaoMenu key={index} onClick={subItem.click}>
                            <TextoItem>
                              <div>{subItem.nome}</div>
                            </TextoItem>
                          </BotaoMenu>
                        ))}
                      </DropDown>
                    )}
                    {item.subAcessoInterno && (
                      <DropDownAcessoInterno
                        ativo={acessoInterno}
                        onMouseLeave={() => subAcessoInterno(false)}
                      >
                        {item.subAcesso.map((subItem, index) => (
                          <BotaoMenu key={index} onClick={subItem.click}>
                            <TextoItem>
                              <div>{subItem.nome}</div>
                            </TextoItem>
                          </BotaoMenu>
                        ))}
                      </DropDownAcessoInterno>
                    )}
                  </BotaoMenu>
                ))}
              </div>
            </>
          )}
        </section>
      </Container>
    </HeaderStyle>
  );
};

export default HeaderPrincipal;
