import React from "react";
import { NUMERO, SISLAM } from "../../../../utils/Constants";
import {
  InfoContainer,
  InfoItem,
  Separador,
  Dados,
  TituloDado,
  NumeroDado,
} from "./style";
import { Slide, Slider } from "../../../../components/slider";
import { Titulo, TituloContainer } from "../../style";

export const NumeroSislam = ({ numeros }) => {
  const settings = {
    breakpoints: {
      1500: { slidesPerView: 4 },
      1200: { slidesPerView: 3 },
      820: { slidesPerView: 2 },
      600: { slidesPerView: 1 },
    },
    spaceBetween: 2,
    navigation: true,
  };
  return (
    <>
      <TituloContainer>
        <Titulo>{NUMERO}</Titulo>
        <Titulo negrito>{SISLAM}</Titulo>
      </TituloContainer>
      <InfoContainer>
        <Slider settings={settings}>
          {numeros.map((item, index) => {
            return (
              <Slide key={index}>
                <InfoItem>
                  <Separador />
                  <Dados>
                    <TituloDado>{item.titulo}</TituloDado>
                    <NumeroDado>{item.numero}</NumeroDado>
                  </Dados>
                </InfoItem>
              </Slide>
            );
          })}
        </Slider>
      </InfoContainer>
    </>
  );
};
