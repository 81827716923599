import React from 'react';

const Arrow = ({secundario}) => {
  return (
    <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 13.5L7 7.5L1 1.5" stroke={secundario ? "#414141" : "#F6F9F6"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Arrow;