import styled from 'styled-components'
import { NavLink } from 'react-router-dom';

export const FooterContainer = styled.div`
  width: 100%;
  bottom: 0;
  position: relative;
`

export const FooterStyle = styled.div`
  height: 75px;
  background-color: ${props => props.theme.colors.secundaria};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FooterBottom = styled.div`
  height: 31px;
  background-color: ${props => props.theme.colors.Azul600};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Imagem = styled.img`
  width: 112px;
  height: 40px;
  padding-left: 32px;
`

export const Container = styled.div`
  padding-right: 33px;
`

export const Creditos = styled.span`
  color: ${props => props.theme.colors.Branco};
  font-size: ${props => props.theme.fontSizes.small};
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
`
export const NavLinkNaoSublinhado = styled(NavLink)`
  text-decoration: none;
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
}
`
export const Label = styled.span`
  color: ${props => props.theme.colors.Branco};
  font-size: ${props => props.theme.fontSizes.small};
  font-weight: 400;
  line-height: 16px;
`

export default NavLinkNaoSublinhado;