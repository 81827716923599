import React from 'react'
import InputEmail from '../../components/inputEmail'
import { ContatoStyled, Imagem1, Imagem2, Imagem3, ButtonVoltar,IconeRedes ,HeaderArrow, HeaderContato, TitleContato,ContatoDiv } from './style'
import { MapaStyled } from './style'
import { useNavigate } from "react-router-dom";
import Facebook from '../../assets/img/Facebook.png'
import Twitter from '../../assets/img/Twitter.png'
import Instagram from '../../assets/img/Instagram.png'
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon'

const Contato = () => {

    const navigate = useNavigate();

return (
    <ContatoDiv>
        <HeaderContato>
            <ButtonVoltar onClick={() => navigate("/")}>
                <HeaderArrow>
                    <span><ArrowLeftIcon /></span>
                </HeaderArrow>
                    <p> Voltar </p>
            </ButtonVoltar>
            <TitleContato> | Contato </TitleContato>
        </HeaderContato>
        <ContatoStyled>
            <div>
                <span>
                        IPAAM - Instituto de Proteção Ambiental do Amazonas
                    <br />
                        Horário de atendimento ao público: das 08:00 às 17:00
                    <br />
                        Endereço: Rua Mário Ypiranga Monteiro, 3280, Parque 10 de Novembro
                    <br />
                        CEP 69.050-030. Manaus – AM
                </span>
                <br />
                <br />

                <span>
                    Para questões sobre acesso, utilização e reclamações sobre os sistemas ONLINE
                    <br />
                    E-mail: sac.sistemas@ipaam.am.gov.br 
                    <br />
                    Whatsapp e telefone: (92) 98429-4370 ou (92) 2123-6758
                </span>
                <br />
                <br />

                <span>
                    Para esclarecimentos e reclamações sobre o licenciamento ambiental:
                    <br />
                    E-mail: atendimento@ipaam.am.gov.br
                </span>
                <br />
                <br />
                <br />
                <span> Redes sociais:</span>
                    <IconeRedes>
                        <Imagem1 onClick={() => window.open("https://www.facebook.com/IpaamAM")} >
                                <img width="35px" height="35" src={Facebook} alt='logo-facebook' />
                        </Imagem1>

                        <Imagem2 onClick={() => window.open("https://www.instagram.com/ipaamam")}>
                                <img width="35px" height="35" src={Instagram} alt='logo-twitter' />
                        </Imagem2>
                        
                        <Imagem3 onClick={() => window.open("https://twitter.com/amipaam")}>
                            <img width="35px" height="35" src={Twitter} alt='logo-twitter' onClick={() => window.open("https://twitter.com/amipaam")} />
                        </Imagem3>
                    </IconeRedes>
                    <br />
                <span> Receba no seu e-mail informações e novidades do IPAAM </span>
                <br />
                <InputEmail />
            </div>
        <div>
            <MapaStyled>
                    <iframe width="100%" height="500" title='mapa'
                    src="https://maps.google.com/maps?width=700&amp;height=700&amp;hl=en&amp;q=Av.%20M%C3%A1rio%20Ypiranga%2C%203280%20-%20Parque%20Dez%20de%20Novembro%2C%20Manaus%20-%20AM%2C%2069053-165+(ipaam)&amp;ie=UTF8&amp;t=&amp;z=19&amp;iwloc=B&amp;output=embed">
                    </iframe>
            </MapaStyled>
        </div>
        </ContatoStyled>
    </ContatoDiv>
)
}

export default Contato;