import React, { useEffect, useState } from "react";
import {
  DEMAIS_ATIVIDADES_SERVICOS,
  DEMAIS_ATIVIDADES_SERVICOS_DESCRICAO,
  DISPENSA_LICENCIAMENTO,
  DISPENSA_LICENCIAMENTO_DESCRICAO,
  LICENCIAMENTO_FAUNA,
  LICENCIAMENTO_FAUNA_DESCRICAO,
  LICENCIAMENTO_FLORESTAL,
  LICENCIAMENTO_FLORESTAL_DESCRICAO,
  LICENCIAMENTO_HIDRICOS,
  LICENCIAMENTO_HIDRICOS_DESCRICAO,
  LICENCIAMENTO_INDUSTRIAL,
  LICENCIAMENTO_INDUSTRIAL_DESCRICAO,
  SAIBA_SOBRE,
  SERVICOS,
} from "../../../../utils/Constants";
import { Titulo, TituloContainer } from "../../style";
import {
  AcoesServicos,
  BotaoServico,
  Container,
  ConteudoServico,
  DescricaoServico,
  ItemServico,
  MenuServicos,
  Separador,
  TextoBotao,
} from "./style";
import Florestal from "./../../../../assets/img/Florestal.png";
import Industrial from "./../../../../assets/img/Industrial.png";
import Fauna from "./../../../../assets/img/Fauna.png";
import Hidricos from "./../../../../assets/img/Hidricos.png";
import Dispensa_Licenciamento from "./../../../../assets/img/Dispensa_licenciamento.png";
import Demais_Atividades from "./../../../../assets/img/Diversos.png";
import Arrow from "../../../../assets/icons/ArrowIcon";
import { useNavigate } from "react-router-dom";

const servicos = [
  {
    id: 1,
    titulo: LICENCIAMENTO_FAUNA,
    descricao: LICENCIAMENTO_FAUNA_DESCRICAO,
    imagem: Fauna,
    background: "#C5C0B3",
    tituloCollapse: "FAUNA_PESCA",
  },
  {
    id: 2,
    titulo: LICENCIAMENTO_HIDRICOS,
    descricao: LICENCIAMENTO_HIDRICOS_DESCRICAO,
    imagem: Hidricos,
    background: "#D9E0E7",
    tituloCollapse: "RECURSOS_MINERAIS_HIDRICOS",
  },
  {
    id: 3,
    titulo: LICENCIAMENTO_FLORESTAL,
    descricao: LICENCIAMENTO_FLORESTAL_DESCRICAO,
    imagem: Florestal,
    background: "#F2EEE3",
    tituloCollapse: "FLORESTAL_AGROPECUARIO",
  },
  {
    id: 4,
    titulo: LICENCIAMENTO_INDUSTRIAL,
    descricao: LICENCIAMENTO_INDUSTRIAL_DESCRICAO,
    imagem: Industrial,
    background: "#CACACA",
    tituloCollapse: "INDUSTRIAL",
  },
  {
    id: 5,
    titulo: DISPENSA_LICENCIAMENTO,
    descricao: DISPENSA_LICENCIAMENTO_DESCRICAO,
    imagem: Dispensa_Licenciamento,
    background: "#CCE2CD",
    tituloCollapse: "DLI",
  },
  {
    id: 6,
    titulo: DEMAIS_ATIVIDADES_SERVICOS,
    descricao: DEMAIS_ATIVIDADES_SERVICOS_DESCRICAO,
    imagem: Demais_Atividades,
    background: "#F3E1D5",
    tituloCollapse: "OUTROS",
  },
];

export const SobreServicos = ({activeServico, setActiveServico}) => {
  const [servicoAtivo, setServicoAtivo] = useState(servicos[0]);
  const navigate = useNavigate();
  const handleClick = () => {
    const accordionName = servicoAtivo.tituloCollapse; 
    navigate(`/servicos?accordionName=${accordionName}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!activeServico) {
      setActiveServico(servicos[0].id);
    } // eslint-disable-next-line
  },[])

  useEffect(() => {
    if (activeServico) {
      const selected = servicos.find(servico => servico.id === activeServico);
      setServicoAtivo(selected);
    }
  },[activeServico])

  return (
    <>
      <TituloContainer id="menuServicos">
        <Titulo>{SAIBA_SOBRE}</Titulo>
        <Titulo negrito>{SERVICOS}</Titulo>
      </TituloContainer>
      <Container backgroundColor={servicoAtivo.background}>
        <MenuServicos>
          {servicos.map((item, index) => {
            return (
              <ItemServico
                key={index}
                active={activeServico === item.id}
                onClick={() => setActiveServico(item.id)}
              >
                {item.titulo}
              </ItemServico>
            );
          })}
        </MenuServicos>
        <Separador />
        <ConteudoServico imagem={servicoAtivo.imagem}>
          <DescricaoServico id={servicoAtivo.id}>{servicoAtivo.descricao}</DescricaoServico>
          <AcoesServicos>
            <BotaoServico botaoAcesso onClick={handleClick}>
              <TextoBotao botaoAcesso>{"Acessar Serviços"}</TextoBotao>
              <Arrow />
            </BotaoServico>
          </AcoesServicos>
        </ConteudoServico>
      </Container>
    </>
  );
};
