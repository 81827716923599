import React from "react";

const ChevronsUp = () => {
  return (
    <svg
      width="30"
      height="35"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0423 13.7917L15.0007 7.75L8.95898 13.7917"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0423 22.2497L15.0007 16.208L8.95898 22.2497"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronsUp;
