import styled from 'styled-components'


export const ContatoDiv = styled.div`
    position: relative;
    min-height: 740px;
`

export const IconeRedes = styled.div`
    display: flex;
    align-items: center;
`

export const ContatoStyled = styled.div`
font-family: 'SF Pro Display';
height: 100%;
display: grid;
grid-template-columns: 1fr 1fr;
width: 100%;
font-size: 22px;
color: #295661;
line-height: 30px;
align-items: stretch;
border: none;
`

export const MapaStyled = styled.div`
display: flex;
grid-column: 2;
margin-top:0%;
`

export const Imagem1 = styled.div`
    padding: 5px;
cursor: pointer;
`

export const Imagem2 = styled.div`
    padding: 5px;

cursor: pointer;
`
export const Imagem3 = styled.div`
    padding: 5px;
cursor: pointer;
`

export const ButtonVoltar = styled.div`
  border: none;
  display:flex;
  color: #295661;
  cursor: pointer;
  margin-right: 5px;
`

export const HeaderArrow = styled.span`
  display: flex;
  align-items: center;
  font-weight: bold;
  align-items: center;
  margin-top: 5px;
  color: #295661;
  margin-right: 5px;
`

export const HeaderContato = styled.span`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  height: 24px;
  color: #295661;
  margin-bottom: 20px;
`

export const TitleContato = styled.span`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  height: 24px;
  color: #295661;
`
