import React from 'react'
import { useState } from 'react'
import { InputStyleEmail } from './style'
import { BotaoCadastrar } from './style'
import cadastrar from "../../services/contato"

const InputEmail = () => {

  const [value, setValue] = useState('');

  function handleChange(event) {
    setValue(event.target.value);
  }

  function handleSubmit() {
    cadastrar(value);
    setValue('');
  }


  return (
    <>
      <InputStyleEmail type='email' placeholder='Seu e-mail' value={value} onChange={handleChange} /> 
        <BotaoCadastrar type='submit' onClick={handleSubmit}>
          Cadastrar
        </BotaoCadastrar>
    </>
  );
};


export default InputEmail