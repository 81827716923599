import React from 'react'
import FooterPrincipal from '../../components/footerPrincipal'
import HeaderPrincipal from '../../components/headerPrincipal'
import { DivPrincipal } from './style'

const BaseLayout = ({children}) => {

  return (
    <>
      <HeaderPrincipal />
      <DivPrincipal>
        {children}
      </DivPrincipal>
      <FooterPrincipal />
    </>
  )
}

export default BaseLayout