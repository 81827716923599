import styled from "styled-components";

export const BotaoCadastrar = styled.div`
display: flex;
padding: 11px 32px;
margin-left: 630px;
margin-top: -52px;
position: absolute;
background: #00C254;
color: #fff;
box-shadow: 14px 15px 21px rgba(0, 0, 0, 0.1);
border-radius: 0px 8px 8px 0px;
cursor: pointer;
`

export const InputStyleEmail = styled.input`
box-sizing: border-box;
padding: 16px 32px 16px 18px;
width: 635px;
height: 52px;
background: #EDEDED;
border: 1px solid #779CA5;
border-radius: 8px;
margin-top: 2px;
outline: 0;
`