import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 1em;
  border: ${props => `1px solid ${props.theme.colors.gray}`};
  background-color: ${props => props.theme.colors.lightBlue};
  font-family: ${props => props.theme.fonts[0]};
`;

export const Texto = styled.h1`
  font-size: ${({ tituloPrincipal, theme: { fontSizes } }) =>
  tituloPrincipal ? fontSizes.large : fontSizes.small};
  color: ${({ theme: { colors } }) => colors.green};
`;

export const Titulo = styled.span`
    font-weight: ${props => (props.negrito ? 'bold' : 300)};
    font-size: 24px;
    line-height: 29px;
    padding: 32px 5px 16px 0px;
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.Preto};
`;

export const TituloContainer =  styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContanierFixo = styled.div`
    position: fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    flex-direction: column;
    display: flex;
    bottom: 0px;
    right: -8px;
    padding: 8% 0%;
    gap: 8px;
    z-index: 1000;
`;

export const Floating = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: table-cell;
    vertical-align: middle; 
    text-align: center;

    background: ${props => props.theme.colors.Verde};

    &:hover {
      cursor: pointer;
    }
`;

export const IconContainer =  styled.div`
  padding: 5px;
`;