import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  DESCRICAO_MODULOS_INTERNO,
  MODULOS_DE_USO,
  INTERNO,
} from "../../utils/Constants";
import { TituloContainer } from "../Home/style";
import {
  CardBody,
  CardHeader,
  LogoCard,
  TituloCard,
  TextoContainer,
  TextoBody,
  Separador,
  TextoBotao,
} from "../Home/components/cardSistemas/style";
import {
  Descricao,
  Titulo,
  CardContainer,
  Card,
  HeaderAcessoInterno,
  BotaoVoltar,
  BotaoCard,
  Pagina,
  HeaderArrow,
} from "./style";
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon";
import { useNavigate } from "react-router-dom";
import { getModulos } from "../../services/modulos";
import LogoAnalise from "../../assets/img/LogoAnalise.png";
import LogoFiscalizacaoOff from "../../assets/img/LogoFiscalizacaoOffline.png";
import LogoFiscalizacaoOn from "../../assets/img/LogoFiscalizacao.png";
import LogoGestaoDemandas from "../../assets/img/LogoGestaoDeDemandas.png";
import LogoSIG from "../../assets/img/LogoSIG.png";

const Conteudo = () => {
  const navigate = useNavigate();

  const [modulos, setModulos] = useState([]);
  const modulosFiltrados = [...modulos].sort((a, b) =>
    a.nome > b.nome ? 1 : -1
  );
  const setDadosModulos = async () => {
    const data = await getModulos();
    setModulos(data);
  };
  useEffect(() => {
    setDadosModulos();
  }, []);

  return (
    <>
      <Pagina>
        <HeaderAcessoInterno>
          <BotaoVoltar onClick={() => navigate("/")}>
            <HeaderArrow>
              <span>
                <ArrowLeftIcon />
              </span>
            </HeaderArrow>
            <p>Voltar </p>
          </BotaoVoltar>
          <p> | Acesso Interno</p>
        </HeaderAcessoInterno>

        <TituloContainer>
          <Titulo>{MODULOS_DE_USO}</Titulo>
          <Titulo negrito>{INTERNO}</Titulo>
        </TituloContainer>
        <Descricao>{DESCRICAO_MODULOS_INTERNO}</Descricao>

        <CardContainer>
          {modulosFiltrados.map((modulo, index) => {
            return (
              <>
                <Card key={index}>
                  <CardHeader>
                    <LogoCard
                      src={
                        modulo.nome === "Análise SISLAM"
                          ? LogoAnalise
                          : modulo.nome === "Fiscalização Offline"
                          ? LogoFiscalizacaoOff
                          : modulo.nome === "Fiscalização Online"
                          ? LogoFiscalizacaoOn
                          : modulo.nome === "Gestão de Demandas"
                          ? LogoGestaoDemandas
                          : LogoSIG
                      }
                      alt="Logo"
                    />
                    <TituloContainer>
                      <TituloCard>{modulo.nome}</TituloCard>
                    </TituloContainer>
                  </CardHeader>
                  <Separador />
                  <CardBody>
                    <TextoContainer>
                      <TextoBody>{modulo.descricao}</TextoBody>
                    </TextoContainer>
                    <BotaoCard
                      onClick={() =>
                        window.open(modulo.link, "_blank", "noreferrer")
                      }
                    >
                      <TextoBotao>Acessar</TextoBotao>
                    </BotaoCard>
                  </CardBody>
                </Card>
              </>
            );
          })}
        </CardContainer>
      </Pagina>
    </>
  );
};

export default Conteudo;
