import React from 'react'
import { Swiper } from 'swiper/react';
import { Navigation, A11y } from 'swiper'

import 'swiper/css';
import 'swiper/css/navigation';
import './Slider.css'

const Slider = ({settings, children}) => {
  return (
    <Swiper
        modules={[Navigation, A11y]}
        {...settings}
    >
       {children}
    </Swiper>
  )
}

export default Slider