import { Button, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import GetAppIcon from "@material-ui/icons/GetApp";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon";
import { getAgendas, getServicosByCodAmbiental } from "../../services/servicos";
import {
  AgendaTextBody,
  AgendaTextContainer,
  AgendaTextTitle,
  ButtonVoltar,
  Content,
  HeaderArrow,
  HeaderServicos,
} from "./style";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { ButtonIcon } from "../../components/botaoIcone/style";
import "./style-accordion.css";

const Servicos = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [accordionData, setAccordionData] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState();
  const [requisitosAtividade, setRequisitosAtividade] = useState({});
  const [isAccordionExpanded, setIsAccordionExpanded] = useState([]); // array que guarda todos os Accordions ativos
  const [
    whichCollapseRequisitosIsExpanded,
    setWhichCollapseRequisitosIsExpanded,
  ] = useState(0); // 1- requisitos administrativos, 2- requisitos tecnicos

  const textosAgendas = {
    FLORESTAL_AGROPECUARIO: [
      {
        titulo: "Gerência de Controle Florestal – GECF",
        texto:
          "Responsável por licenciar, monitorar e controlar as atividades de base florestal. Analisar as solicitações  de declarações, autorizações, operar o sistema de controle florestal – DOF e o SINAFLOR. Analisar  planos, projetos e estudos referentes ao licenciamento ambiental.",
      },
      {
        titulo: "Gerência de Controle Agropecuário – GCAP",
        texto:
          "Responsável por licenciar, monitorar e controlar atividades agrícolas, pecuárias, agroindustrial,  comercialização e armazenamento de agrotóxicos, abatedouros, matadouros e suspensão vegetal  para uso alternativo do solo em imóveis rurais.",
      },
    ],
    INDUSTRIAL: [
      {
        titulo: "Gerência de Licenciamento Industrial – GELI",
        texto:
          "Responsável por licenciar, monitorar e controlar as atividades relacionadas às atividades industriais e  construções civis; analisar planos, projetos e estudos referentes ao licenciamento ambiental. Minutar  as licenças ambientais.",
      },
    ],
    RECURSOS_MINERAIS_HIDRICOS: [
      {
        titulo: "Gerência de Recursos Minerais – GERM",
        texto:
          "Responsável por licenciar, monitorar e controlar as atividades relacionadas ao uso dos Recursos  Minerais. Analisar planos, projetos e estudos referentes ao licenciamento ambiental. Minutar as  licenças ambientais.",
      },
      {
        titulo: "Gerência de Recursos Hídricos – GERH",
        texto:
          "Responsável por licenciar, monitorar e controlar as atividades relacionadas a uso dos Recursos  Hídricos; Analisar os pedidos de outorga de direito de uso de recursos hídricos; Analisar planos,  projetos e estudos referentes ao licenciamento ambiental.",
      },
    ],
    FAUNA_PESCA: [
      {
        titulo: "Gerência de Fauna – GFAU",
        texto:
          "Responsável por licenciar, monitorar e controlar as atividades relacionadas a uso de fauna.  Responsável pela gestão da fauna silvestre em cativeiro e pelas autorizações e análises dos impactos  causados à fauna pelos empreendimentos licenciados pelo IPAAM.",
      },
      {
        titulo: "Gerência de Controle de Pesca – GECP",
        texto:
          "Responsável por licenciar, monitorar e controlar as atividades de aquicultura, pesca amadora,  beneficiamento de pescado, controle de estoque de pescado declarado durante o período do defeso  estabelecido por normas estaduais, analisar planos, projetos e estudos referente ao licenciamento  ambiental.",
      },
    ],
    DLI: [
      {
        titulo: "Coordenação dos Sistemas Informatizados - CSI/IPAAM",
        texto:
          "Responsável pelo atendimento aos usuários dos sistemas tecnológicos, orientando quanto aos  procedimentos para uso e suporte a solicitações realizadas via sistemas digitais.",
      },
      {
        titulo: "Gerência de Gestão de Documentos e Protocolo – GEPR",
        texto:
          "Responsável pelo atendimento ao público, orientando quanto a necessidade ou não de licenciamento  ambiental; Receber e protocolar documentos diversos, inclusive dos centros multifuncionais dos  municípios de Parintins, Humaitá e Boca do Acre.",
      },
    ],
    OUTROS: [
      {
        titulo: "Gerência de Gestão de Documentos e Protocolo – GEPR",
        texto:
          "Responsável pelo atendimento ao público, orientando quanto a necessidade ou não de licenciamento  ambiental; Receber e protocolar documentos diversos, inclusive dos centros multifuncionais dos  municípios de Parintins, Humaitá e Boca do Acre.",
      },
    ],
  };

  const handleAccordionClick = (item) => {
    if (item.tipo === "AGENDA") {
      if (searchParams.get("accordionName") === item.codigo) {
        setSearchParams((prevParams) => {
          prevParams.delete("accordionName");
          return new URLSearchParams(prevParams);
        });
      } else {
        setSearchParams({ accordionName: item.codigo });
      }
    }

    const breadcrumbIndex = breadcrumb.findIndex((b) => b === item.nome);

    if (breadcrumbIndex !== -1) {
      const newBreadcrumb = breadcrumb.slice(0, item.nivel);
      setBreadcrumb(newBreadcrumb);
    } else {
      const newBreadcrumb = breadcrumb.slice(0, item.nivel);
      newBreadcrumb.push(item.nome);
      setBreadcrumb(newBreadcrumb);
    }

    const accordionIndex = isAccordionExpanded.findIndex(
      (b) => b.codigo === item.codigo
    );

    if (accordionIndex !== -1) {
      const newIsAccordionExpanded = isAccordionExpanded.slice(0, item.nivel);
      setIsAccordionExpanded(newIsAccordionExpanded);
      setActiveAccordion(
        newIsAccordionExpanded[newIsAccordionExpanded.length - 1]
      );
    } else {
      const newIsAccordionExpanded = isAccordionExpanded.slice(0, item.nivel);
      newIsAccordionExpanded.push(item);
      setIsAccordionExpanded(newIsAccordionExpanded);
      setActiveAccordion(item);
    }
  };

  const handleClickNoAccordionButton = async (item) => {
    if (item.tipo === "ATIVIDADE") {
      const requisitos = await getServicosByCodAmbiental(item.codigo);
      setRequisitosAtividade(requisitos);
      setWhichCollapseRequisitosIsExpanded(0);
    } else if (item.tipo === "LINK") {
      if (item.link.includes(".docx")) {
        var a = document.createElement("A");
        a.href = item.link;
        a.download = item.link.substr(item.link.lastIndexOf("/") + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        window.open(item.link, "_blank").focus();
      }
    }
    handleAccordionClick(item);
  };

  const handleOpenLink = (link) => {
    window.open(link, "_blank").focus();
  };

  const getAccordionData = async () => {
    const agendas = await getAgendas();
    setAccordionData(agendas);
  };

  const renderChildren = (children, tabSize) => {
    if (children.length === 0) return null;
    if (!tabSize) {
      tabSize = 1;
    } else {
      tabSize++;
    }

    return (
      <Accordion allowZeroExpanded className="accordion-children">
        {children.map((c, index) => (
          <Fragment key={index}>
            {c.tipo === "TIPOLOGIA" ||
            c.tipo === "TEXTO" ||
            c.tipo === "TIPOLOGIA_ITEM" ? (
              <AccordionItem
                key={c.id + "-" + c.codigo + "-children-AccordionItem"}
                dangerouslySetExpanded={
                  isAccordionExpanded.findIndex(
                    (accor) => accor?.codigo === c.codigo
                  ) !== -1
                }
              >
                <AccordionItemHeading
                  key={c.id + "-" + c.codigo + "-children-AccordionItemHeading"}
                  onClick={() => handleAccordionClick(c)}
                >
                  <AccordionItemButton
                    style={{ paddingLeft: tabSize * 18 }}
                    className={`accordion__button nivel-${tabSize - 1}`}
                  >
                    {c.nome}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel
                  key={c.id + "-" + c.codigo + "-children-AccordionItemPanel"}
                  className={
                    activeAccordion === c ? "selected-accordion-panel" : ""
                  }
                >
                  {c.itensServicoFilhos &&
                    c.itensServicoFilhos.length > 0 &&
                    renderChildren(c.itensServicoFilhos, tabSize)}
                </AccordionItemPanel>
              </AccordionItem>
            ) : (
              <div
                onClick={() => handleClickNoAccordionButton(c)}
                className={
                  activeAccordion && activeAccordion.codigo === c.codigo
                    ? "no-accordion-button no-accordion-button-selected"
                    : `no-accordion-button nivel-${tabSize - 1}`
                }
              >
                <Typography style={{ paddingLeft: tabSize * 18 }}>
                  {c.nome}
                </Typography>
              </div>
            )}
          </Fragment>
        ))}
      </Accordion>
    );
  };

  const renderAccordionItem = (node) => {
    if (!node) return null;

    return (
      <AccordionItem
        key={node.id + "-" + node.codigo + "-accordion"}
        uuid={node.codigo}
        dangerouslySetExpanded={
          isAccordionExpanded.findIndex(
            (accor) => accor?.codigo === node.codigo
          ) !== -1
        }
      >
        <AccordionItemHeading onClick={() => handleAccordionClick(node)}>
          <AccordionItemButton>{node.nome}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {node.itensServicoFilhos &&
            node.itensServicoFilhos.length > 0 &&
            renderChildren(node.itensServicoFilhos, 1)}
        </AccordionItemPanel>
      </AccordionItem>
    );
  };

  const renderAccordionAgendas = (data) => {
    return (
      <Accordion
        allowZeroExpanded
        preExpanded={[searchParams.get("accordionName")]}
        className="accordion-agendas"
      >
        {data.map((item) => renderAccordionItem(item))}
      </Accordion>
    );
  };

  const renderBreadcrumb = () => {
    return (
      <div style={{ display: "flex", marginBottom: "25px" }}>
        <span
          style={{ color: "#295661", fontSize: "16px", fontWeight: "bold" }}
        >
          Início{" "}
        </span>
        {breadcrumb.map((b, index) => (
          <Fragment key={index}>
            <span
              key={index + "arrow"}
              style={{
                color: "#295661",
                fontSize: "16px",
                fontWeight: "bold",
                marginLeft: "5px",
              }}
            >
              {"> "}
            </span>
            <span
              key={index}
              style={{
                color: index === breadcrumb.length - 1 ? "#00C254" : "#295661",
                fontSize: "16px",
                fontWeight: "bold",
                marginLeft: "5px",
              }}
            >
              {b}
            </span>
          </Fragment>
        ))}
      </div>
    );
  };

  const renderAccordionRequisitos = (
    requisitos,
    titulo,
    isOpen,
    valueToBeSet
  ) => (
    <Accordion allowZeroExpanded className="accordion-requisitos">
      <AccordionItem
        dangerouslySetExpanded={isOpen}
        onClick={() => {
          if (whichCollapseRequisitosIsExpanded === valueToBeSet) {
            setWhichCollapseRequisitosIsExpanded(0);
          } else {
            setWhichCollapseRequisitosIsExpanded(valueToBeSet);
          }
        }}
      >
        <AccordionItemHeading>
          <AccordionItemButton>{titulo}</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <>
            {requisitos.map((r, index) => (
              <Fragment key={index}>
                <Typography className="requisitos-title">{r.nome}</Typography>
                <ol>
                  {r.documentos.map((doc) => (
                    <li
                      style={{
                        paddingBottom: 10,
                        textAlign: "justify",
                      }}
                      key={doc.id}
                    >
                      {doc.nome}
                      {doc.linkTermoReferencia ? (
                        <ButtonIcon
                          children={
                            <OpenInNewOutlinedIcon
                              fontSize="small"
                              style={{ marginBottom: -4 }}
                            />
                          }
                          onClick={() =>
                            handleOpenLink(doc.linkTermoReferencia)
                          }
                          title="Abrir termo de referência em nova aba"
                        />
                      ) : (
                        <></>
                      )}
                    </li>
                  ))}
                </ol>
              </Fragment>
            ))}
          </>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );

  const renderAgendaText = (agenda) => {
    const textos = textosAgendas[agenda.codigo];

    return (
      <>
        {textos && textos.length > 1 ? (
          <Typography>Setores envolvidos:</Typography>
        ) : (
          <Typography>Setor envolvido:</Typography>
        )}
        {textos &&
          textos.map((t, index) => (
            <AgendaTextContainer key={index}>
              <AgendaTextTitle>{t.titulo}</AgendaTextTitle>
              <AgendaTextBody>{t.texto}</AgendaTextBody>
            </AgendaTextContainer>
          ))}
      </>
    );
  };

  useEffect(() => {
    getAccordionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accordionData && accordionData.length > 0) {
      const accordionName = searchParams.get("accordionName");
      if (accordionName) {
        const actvAccordion = accordionData.filter(
          (a) => a.codigo === accordionName
        )[0];

        if (!actvAccordion) {
          return;
        }

        setActiveAccordion(actvAccordion);

        const newBreadcrumb = [];
        newBreadcrumb.push(actvAccordion.nome);
        setBreadcrumb(newBreadcrumb);

        const newIsAccordionExpanded = [];
        newIsAccordionExpanded.push(actvAccordion);
        setIsAccordionExpanded(newIsAccordionExpanded);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accordionData]);

  return (
    <div>
      <div>
        <HeaderServicos>
          <ButtonVoltar onClick={() => navigate("/")}>
            <HeaderArrow>
              <span>
                <ArrowLeftIcon />
              </span>
            </HeaderArrow>
            <p>Voltar </p>
          </ButtonVoltar>
          <p> | Serviços</p>
        </HeaderServicos>
        {renderBreadcrumb()}
      </div>
      <div style={{ display: "Grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          {accordionData && accordionData.length > 0 && (
            <Paper elevation={4}>{renderAccordionAgendas(accordionData)}</Paper>
          )}
        </div>
        <Content>
          <div style={{ marginBottom: "15px" }}>
            <span
              style={{ color: "#295661", fontSize: "26px", fontWeight: "bold" }}
            >
              {activeAccordion
                ? activeAccordion.nome
                : "Você ainda não selecionou nenhuma agenda"}
            </span>
          </div>
          {activeAccordion && activeAccordion.tipo !== "ATIVIDADE" ? (
            <div>
              <span style={{ color: "#295661", fontSize: "22px" }}>
                {activeAccordion && activeAccordion.nivel === 0
                  ? renderAgendaText(activeAccordion)
                  : "Selecione do lado esquerdo por uma agenda, " +
                    "termo ou assunto desejado"}
              </span>
            </div>
          ) : (
            <></>
          )}
          <div style={{ paddingTop: "20px" }}>
            {activeAccordion && activeAccordion.tipo === "ATIVIDADE" && (
              <>
                {requisitosAtividade &&
                  requisitosAtividade.codAmbiental ===
                    activeAccordion.codigo && (
                    <div>
                      {renderAccordionRequisitos(
                        requisitosAtividade.requisitosAdministrativos,
                        "Requisitos Administrativos",
                        whichCollapseRequisitosIsExpanded === 1,
                        1
                      )}
                      {renderAccordionRequisitos(
                        requisitosAtividade.requisitosTecnicos,
                        "Requisitos Técnicos",
                        whichCollapseRequisitosIsExpanded === 2,
                        2
                      )}
                    </div>
                  )}
                <div className="buttons-atividade">
                  <Button
                    startIcon={<GetAppIcon />}
                    variant="outlined"
                    onClick={() => handleOpenLink(requisitosAtividade.linkPdf)}
                  >
                    Documento em PDF
                  </Button>
                  <Button
                    startIcon={<OpenInNewOutlinedIcon />}
                    variant="outlined"
                    onClick={() =>
                      handleOpenLink(
                        "https://sistemas.ipaam.am.gov.br/licenciamento/"
                      )
                    }
                  >
                    Acessar o sistema
                  </Button>
                </div>
              </>
            )}
          </div>
        </Content>
      </div>
    </div>
  );
};
export default Servicos;
