import api from "./api"

export const getAlertas = async () => {
    try {
      const fetchData = await api.get('/api/dashboard/alertas')
      return fetchData.data
    } catch (error) {
      console.log(error)
    }
  }

export const getAlertasQntDias = async (qntDias) => {
  try {
    const fetchData = await api.get(`/api/dashboard/alertas/${qntDias}`)
    return fetchData.data
  } catch (error) {
    console.log(error)
  }
}