import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ChevronsUp from '../../assets/icons/ChevronsUpIcon'
import Whatsapp from '../../assets/icons/WhatsappIcon'
import { getAlertas, getAlertasQntDias } from '../../services/dashboard'
import { getSistemas } from '../../services/sistemas'
import { NumeroSislam } from './components/numeroSislam'
import PainelServicos from './components/painelServicos'
import { SobreServicos } from './components/sobreServicos'
import SobreSistemas from './components/sobreSistemas'
import { ContanierFixo, Floating, IconContainer } from './style'

const Home = () => {
  const [sistemas, setSistemas] = useState([]);
  const [dadosNumerosSislam, setDadosNumerosSislam] = useState([]);
  const [activeServico, setActiveServico] = useState();

  const setDadosSistemas = async () => {
    const data = await getSistemas();
    setSistemas(data)
  }

  async function populaDadosDashboard () {
    const data = await getAlertas();
    const alerta7Dias = await getAlertasQntDias(7);
    const alerta30Dias = await getAlertasQntDias(30);
    setDadosNumerosSislam([{
      titulo: 'Solicitações atendidas',
      numero: data.alertasAtivos
    },
    {
      titulo: 'DIs Emitidas',
      numero: data.quantidadeDI
    },
    {
      titulo: 'Licenças ambientais',
      numero: data.quantidadeLicenca
    },
    {
      titulo: 'Licenças emitidas nos últimos 7 dias',
      numero: alerta7Dias
    },
    {
      titulo: 'Licenças emitidas nos últimos 30 dias',
      numero: alerta30Dias
    }
  ])
  }

  useEffect(() => {
    setDadosSistemas()
    populaDadosDashboard()
  },[]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  function openWhatsApp() {  
    window.open('https://web.whatsapp.com/send?phone=+55(92)98429-4370');  
    }
    
  return (
    <>
      <PainelServicos setActiveServico={setActiveServico} />
      <SobreServicos 
        activeServico={activeServico}
        setActiveServico={setActiveServico}
      />
      <SobreSistemas sistemas={sistemas}/>
      <NumeroSislam numeros={dadosNumerosSislam}/>
      <ContanierFixo>
        <Floating onClick={() => openWhatsApp()}>
          <IconContainer>
            <Whatsapp />
          </IconContainer>
        </Floating>
        <Floating onClick={() => scrollToTop()}>
        <IconContainer>
          <ChevronsUp />
        </IconContainer>
        </Floating>
      </ContanierFixo>
    </>
  )
}

export default Home