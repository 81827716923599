import styled from "styled-components";

export const InfoContainer = styled.div`
    width: 100%;
    height: 150px;
    border-radius: 20px;
    background-color: ${props => props.theme.colors.secundaria};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const InfoItem = styled.div`
    display: flex;
    flex-direction: row;
`;
export const Separador = styled.div`
    background: ${props => props.theme.colors.Branco};
    border-radius: 31px;
    width: 3px;
    height: 50px;
    margin-right: 0.5rem;
`;

export const Dados = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const TituloDado = styled.span`
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: ${props => props.theme.colors.Branco};
`;

export const NumeroDado = styled.span`
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: ${props => props.theme.colors.Branco};
`;