import styled from 'styled-components'

export const HeaderDuvidas = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  height: 24px;
  color: #295661;
  margin-bottom: 20px;
`

export const HeaderArrow = styled.span`
  display: flex;
  align-items: center;
  /* font-size: 28px; */
  font-weight: bold;
  align-items: center;
  margin-top: 5px;
  color: #295661;
  margin-right: 5px;
`
export const ButtonVoltar = styled.div`
  border: none;
  display:flex;
  color: #295661;
  cursor: pointer;
  margin-right: 5px;
  `

export const TitlePerguntas = styled.div`
  border: none;
  display:flex;
  color: #295661;
  margin-left: 10px;
  font-weight:bolder;
  font-size: 30px;
`